import styled from "@emotion/styled"
import { Container, Grid } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.text.light};
  padding: 0;
  display: flex;

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
  }
`

export const CustomRow = styled.div`
  max-width: 1200px;
  margin: auto;
`

export const CustomContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 80%;

  ${({ theme }) => theme.breakpoints.down("lg")} {
    width: 100%;
    justify-content: left;
  }
`

export const TextWrapper = styled.div`
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 2em;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-right: 11em;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    margin-right: 9em;
  }
`

export const ContentGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 1em;
`

export const ImageGrid = styled(Grid)`
  ${({ theme }) => theme.breakpoints.down("xs")} {
    min-height: 278px;
  }
`

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.typography.pxToRem(32)};
  line-height: ${({ theme }) => theme.typography.pxToRem(38)};
  font-weight: 400;

  font-family: ${({ theme }) => theme.fonts.secondary};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(40)};
    line-height: ${({ theme }) => theme.typography.pxToRem(42)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 335px;
    font-size: ${({ theme }) => theme.typography.pxToRem(48)};
    line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  }
`
export const VideoContainer = styled.div`
  width: 100%;
  padding-top: 3em;
  padding-bottom: 3em;
  position: relative;

  &::before {
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    width: 75%;
    border-radius: 0 0 10px 10px;
    background: ${({ theme }) => theme.palette.primary.main};
  }
`

export const VideoImage = styled(CustomImage)`
  width: 100%;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
  cursor: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='50' cy='50' r='49.5' stroke='white'/%3E%3Cpath d='M43 41L57 50L43 59V41Z' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M57 50L43.5 57.7942L43.5 42.2058L57 50Z' fill='white'/%3E%3C/svg%3E%0A"),
    auto;
`
