import React, { useState } from "react"
import LoadableModalVideo from "../../components/loadable-modal-video/loadable-modal-video"

import "react-modal-video/css/modal-video.css"
import * as S from "./what-our-customers-say.styles.jsx"

const WhatOurCustomersSay = ({ title, videoId, videoFile, videoImage }) => {
  const [isOpen, setIsOpen] = useState(false)
  
  if (!videoId && !videoFile) return null

  return (
    <S.Wrapper>
      <S.CustomContainer>
        <S.TextWrapper>
          <S.Title>{title}</S.Title>
        </S.TextWrapper>
      </S.CustomContainer>
      <S.VideoContainer onClick={() => setIsOpen(true)}>
        <S.VideoImage img={videoImage} alt="Hear from our customers" />
      </S.VideoContainer>
      <LoadableModalVideo
        channel={videoFile?.sourceUrl ? "custom" : "youtube"}
        isOpen={isOpen}
        videoId={videoId}
        url={videoFile?.sourceUrl}
        autoplay={1}
        onClose={() => setIsOpen(false)}
      />
    </S.Wrapper>
  )
}
export default WhatOurCustomersSay
