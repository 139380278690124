import React from "react"

import * as S from "./lets-chat-parallax.styles.jsx"
import { graphql, useStaticQuery } from "gatsby"
import CustomButton from "../../components/custom-button/custom-button.component"
import { Parallax } from "react-scroll-parallax"

const LetsChatParallax = ({
  backgroundImage,
  letsChatTitle,
  letsChatContent,
  demoLink,
}) => {
  const staticQuery2 = useStaticQuery(graphql`
    query {
      circleImage: file(relativePath: { eq: "circle.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      semicircleImage: file(relativePath: { eq: "semi-circle.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `)
  return (
    <S.Section>
      <S.CustomContainer>
        <S.ImageWrapper>
          <S.Image img={backgroundImage} />
          <S.CircleDiv>
            <Parallax translateY={[-30, 90]}>
              <S.CircleImage img={staticQuery2.circleImage} />
            </Parallax>
          </S.CircleDiv>
          <S.SemiCircleDiv>
            <Parallax translateY={[-20, 35]}>
              <S.SemiCircleImage img={staticQuery2.semicircleImage} />
            </Parallax>
          </S.SemiCircleDiv>
          <S.BoxContainer>
            <S.LetsChatTitle>{letsChatTitle}</S.LetsChatTitle>
            <S.LetsChatContent>{letsChatContent}</S.LetsChatContent>
            <S.ButtonContainer>
              <CustomButton
                className="green"
                href={"/book-a-demo"}
                disableRipple
              >
                Book a Demo
              </CustomButton>
            </S.ButtonContainer>
          </S.BoxContainer>
        </S.ImageWrapper>
      </S.CustomContainer>
    </S.Section>
  )
}
export default LetsChatParallax
