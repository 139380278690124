import styled from "@emotion/styled"
import { Box } from "@mui/system"
import { CircularProgress } from "@mui/material"
import CustomButton from "../custom-button/custom-button.component"
import CustomInput from "../custom-input/custom-input.component"

export const FormSpinner = styled(Box)`
  position: absolute;
  left: 5px;
  right: 0;
  top: 19px;
  bottom: 0;
  width: 17%;
  height: 17%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`

export const CustomSpinner = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.text.primary};
  width: 15px !important;
  height: 15px !important;
`

export const CustomForm = styled.form`
  width: 100%;
  max-width: 700px;
  position: relative;
`

export const CustomTextField = styled(CustomInput)`
  &.MuiFormControl-root {
    display: flex;
    align-items: center;
  }

  .MuiOutlinedInput-input {
    color: black;
  }

  .MuiOutlinedInput-root {
    border-radius: 100px;
    height: 54px;
  }

  input {
    padding-left: 1.5em;
  }

  .MuiInputLabel-root {
    opacity: 0.7;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.palette.primary.main} !important;
  }
`

export const SubmitCustomButton = styled(CustomButton)`
  &.MuiButton-root {
    padding: 0.8em 2em;
    margin-top: 1em;
  }
`

export const Message = styled.div`
  padding-top: 1em;
  margin: 0;
  padding-bottom: 0;
  color: red;
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
`
