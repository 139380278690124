import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled.section`
  padding-top: 6.25em;
  padding-bottom: 6.25em;
`

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.typography.pxToRem(38)};
  line-height: ${({ theme }) => theme.typography.pxToRem(48)};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(45)};
    line-height: ${({ theme }) => theme.typography.pxToRem(54)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(48)};
    line-height: ${({ theme }) => theme.typography.pxToRem(58)};
  }
`

export const Description = styled.p`
  color: ${({ theme }) => theme.palette.text.content};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(21)};
  font-weight: 400;
`
