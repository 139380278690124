import React from "react"
import PropTypes from "prop-types"
import * as S from "./home-hero.styles"
import { Container, Grid } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import { Fade } from "react-awesome-reveal"

import { Parallax } from "react-scroll-parallax"
import parse from "html-react-parser"

export const HomeHero = ({
  extraTitle,
  title,
  paragraph,
  buttonLink,
  phoneImage,
  backgroundImage,
  plantImage,
  clientsLogos,
  classname,
  smallTitle,
}) => {
  const staticQuery = useStaticQuery(graphql`
    query {
      homeCircleBack: file(relativePath: { eq: "home_circle_background.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      homeCircleGreen: file(relativePath: { eq: "home_circle.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      homeSemiCircle: file(relativePath: { eq: "home_Ellipse_2.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      homeCircleYellow: file(relativePath: { eq: "home_Ellipse_3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  if (!title) return null
  return (
    <S.Wrapper id="overview">
      <Container>
        <S.CustomGrid container>
          <Grid item md={6} sm={12} xs={12}>
            <S.WrapperLeft className={classname}>
              <S.ExtraTitle className="treez" component="h2">
                {extraTitle}
              </S.ExtraTitle>
              <S.Title
                component="h1"
                className={smallTitle ? "smallTitle treez" : ""}
              >
                {title && parse(title)}
              </S.Title>
              <S.Paragraph className="treez">{paragraph}</S.Paragraph>
              {buttonLink?.url ? (
                <S.Button href={buttonLink.url} disableRipple>
                  {buttonLink.title}
                </S.Button>
              ) : (
                <S.ButtonSmoothly
                  to="benefits"
                  spy={true}
                  smooth={true}
                  offset={-100}
                >
                  Learn More
                </S.ButtonSmoothly>
              )}
            </S.WrapperLeft>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <S.ImagesWrapper>
              <S.BackgroundCircle img={staticQuery.homeCircleBack} />
              <S.CircleDiv>
                <Parallax translateY={[-150, 40]}>
                  <Fade triggerOnce delay={300}>
                    <S.CircleYellowDiv />
                  </Fade>
                </Parallax>
              </S.CircleDiv>
              <S.BgFade triggerOnce duration={1000}>
                <S.BackgroundImage img={backgroundImage} />
              </S.BgFade>

              <Fade triggerOnce delay={800}>
                <S.PlantWrapper>
                  <S.CircleGreenDiv>
                    <S.SemiCirceParallaxDiv>
                      <Parallax translateY={[50, -70]}>
                        <S.SemiCircleDiv />
                      </Parallax>
                    </S.SemiCirceParallaxDiv>
                    <S.PlantImage img={plantImage} />
                  </S.CircleGreenDiv>
                </S.PlantWrapper>
              </Fade>

              <S.CustomFade
                triggerOnce
                keyframes={S.CustomAnimation}
                delay={500}
              >
                <S.PhoneImage img={phoneImage} />
              </S.CustomFade>
            </S.ImagesWrapper>
          </Grid>
        </S.CustomGrid>
      </Container>
      {/*<ClientsSlider logos={clientsLogos} />*/}
    </S.Wrapper>
  )
}

HomeHero.propTypes = {
  extraTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string,
  buttonLink: PropTypes.object,
  phoneImage: PropTypes.object,
  backgroundImage: PropTypes.object,
  plantImage: PropTypes.object,
  clientsLogos: PropTypes.array,
}
