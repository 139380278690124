import React, { useEffect, useState } from "react"

import * as S from "./book-demo-section.styles.jsx"
import BookDemoForm from "../../components/book-demo-form/book-demo-form.component"
import { Container, Typography } from "@mui/material"

const BookDemoSection = ({ title, description }) => {
  const [successMessage, setSuccessMessage] = useState("")
  return (
    <S.Wrapper>
      <Container>
        {successMessage ? (
          <Typography style={{ fontSize: "2.5rem" }}>
            <b>{successMessage}</b>
          </Typography>
        ) : (
          <>
            <S.Title>{title}</S.Title>
            <S.Description>{description}</S.Description>
            <BookDemoForm setSuccessMessage={setSuccessMessage} />
          </>
        )}
      </Container>
    </S.Wrapper>
  )
}
export default BookDemoSection
