import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { setFormUtmParams, submitHubspotForm } from "../../utils/utils"
import { Grid, Typography } from "@mui/material"
import * as S from "./book-demo-form.styles"

export const BookDemoForm = ({
  setSuccessMessage,
  shouldSendCookies = true,
}) => {
  const portalId = "21349178"
  const formId = "34df87c5-be28-4de6-9d5e-d4777411686a"
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  })

  const onSubmit = async data => {
    // console.log("data, ", data)
    setErrorMessage("")
    setIsLoading(true)

    submitHubspotForm(
      {
        ...data,
      },
      portalId,
      formId,
      shouldSendCookies
    )
      .then(res => {
        setIsLoading(false)
        // console.log(res.data)

        if (res && res.data) {
          if (res.data.status === "error") {
            res.data.errors.map(error => {
              if (error.message.split("'")[1].split(".")[1] === "email") {
                if (error.errorType === "INVALID_EMAIL") {
                  setErrorMessage("Invalid email , please check and try again.")
                } else if (error.errorType === "BLOCKED_EMAIL") {
                  setErrorMessage("Blocked email , please use another email.")
                }
              } else if (error.errorType === "NUMBER_OUT_OF_RANGE") {
                setErrorMessage(
                  "Invalid phone number, please check and try again."
                )
              }
            })
          } else if (res && res.data) {
            //navigate("/thank-you?form=contact-sales")
            setSuccessMessage("Thank you for your subscription")
          } else {
            setErrorMessage(
              "There was an error with your submission. Please try again."
            )
          }
        }
      })
      .catch(e => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    setFormUtmParams(setValue)
  }, [])

  return (
    <>
      <S.CustomForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <S.CustomTextField
              placeholder="First Name*"
              name="firstname"
              errors={errors}
              autoComplete="given-name"
              register={register}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <S.CustomTextField
              placeholder="Last Name*"
              name="lastname"
              autoComplete="family-name"
              errors={errors}
              register={register}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <S.CustomTextField
              placeholder="Email*"
              type="email"
              autoComplete="email"
              name="email"
              errors={errors}
              register={register}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <S.CustomTextField
              placeholder="Phone Number*"
              type="tel"
              name="phone"
              autoComplete="tel"
              errors={errors}
              register={register}
              required
            />
          </Grid>
        </Grid>
        {errorMessage && (
          <S.Message color="red">
            <p>{errorMessage}</p>
          </S.Message>
        )}
        <S.SubmitCustomButton
          className="green"
          type="submit"
          disabled={isLoading}
          disableRipple
        >
          {isLoading && (
            <S.FormSpinner>
              <S.CustomSpinner />
            </S.FormSpinner>
          )}
          Submit
        </S.SubmitCustomButton>
      </S.CustomForm>
    </>
  )
}

export default BookDemoForm
