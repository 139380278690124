import styled from "@emotion/styled"
import { Parallax, ParallaxBanner } from "react-scroll-parallax"
import { Container } from "@mui/material"
import CustomImage from "../../components/custom-image/custom-image.component"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Section = styled(SectionWrapper)`
  padding-top: 2em;
  padding-bottom: 2em;
  position: relative;
  background: ${({ theme }) => theme.palette.primary.light};
  // &::before {
  //   content: "";
  //   background: ${({ theme }) => theme.palette.primary.light};
  //   position: absolute;
  //   top: 35%;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  // }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-bottom: 12em;
    min-height: 700px;
    height: 100%;
  }
`

export const CustomContainer = styled(Container)`
  display: flex;
  justify-content: center;
`

export const CircleImage = styled(CustomImage)`
  width: 110px;
`

export const CircleDiv = styled.div`
  position: absolute;
  top: -30px;
  left: -15px;
  z-index: -1;

  ${({ theme }) => theme.breakpoints.up("md")} {
    left: -50px;
    top: 30%;
  }
`

export const SemiCircleImage = styled(CustomImage)`
  width: 80px;
`
export const SemiCircleDiv = styled.div`
  position: absolute;
  z-index: 5;
  top: -20px;
  right: -50px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    top: -50px;
    right: 100px;
  }
`

export const Image = styled(CustomImage)`
  border-radius: 8px;
  overflow: hidden;
`

export const ImageWrapper = styled.div`
  position: relative;
  max-width: 912px;
  width: 100%;
  z-index: 3;
`

export const CustomParallax = styled(Parallax)`
  max-width: 912px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
`

export const BoxContainer = styled.div`
  background: ${({ theme }) => theme.palette.text.light};
  padding: 2em 2em 1em 2em;
  width: 100%;
  max-width: 400px;
  border-radius: 8px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    position: absolute;
    z-index: 3;
    top: 70%;
    right: 0;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    top: 70%;
    right: 0;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    top: 70%;
    right: -100px;
  }
`

export const LetsChatTitle = styled.h2`
  font-size: ${({ theme }) => theme.typography.pxToRem(48)};
  line-height: ${({ theme }) => theme.typography.pxToRem(58)};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-weight: 400;
`
export const LetsChatContent = styled.p`
  color: ${({ theme }) => theme.palette.text.content};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(21)};
  font-weight: 400;
`

export const ButtonContainer = styled.div`
  margin-top: 3em;
  margin-bottom: 2em;
`
